import React from "react"
import styles from './content_text_highlighted.module.scss'

const ContentTextHighlighted = ({ data }) => {
	const { text } = data
	return(
		<div
			dangerouslySetInnerHTML={{ __html: unescape(text) }}
			className={styles.container}
		/>
	)
}

export default ContentTextHighlighted
