import React from "react"
import styles from './content_text_small_size.module.scss'

const ContentTextSmallSize = ({ data }) => {
	//onsole.log(data)
	const { text } = data
	return(
		<div
			dangerouslySetInnerHTML={{ __html: unescape(text) }}
			className={styles.container}
		/>
	)
}

export default ContentTextSmallSize
