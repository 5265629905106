import React from "react"

import ContentImageSingle from './content_image_single'
import ContentTextHighlighted from './content_text_highlighted'
import ContentTextRegularSize from './content_text_regular_size'
import ContentTextSmallSize from './content_text_small_size'
import ContentBlocksImageText from './content_blocks_image_text'
import ContentBlocksImageTextInternalLink from './content_blocks_image_text_internal_link'
import ContentListType1 from './content_list_type1'
import LocationMap from './content_location_map'

const Content = all => {
	const { content } = all
	
	return content.map((contentData, index) => {
		switch (contentData.type) {
			case 'image_single':
				return (<ContentImageSingle key={index} data={contentData.data} />)
		
			case 'text_highlighted':
				return (<ContentTextHighlighted key={index} data={contentData.data} />)
		
			case 'text_regular_size':
				return (<ContentTextRegularSize key={index} data={contentData.data} />)
			
			case 'text_small_size':
				return (<ContentTextSmallSize key={index} data={contentData.data} />)
			
			case 'blocks_image_text':
				return (<ContentBlocksImageText key={index} data={contentData.data} />)

			case 'blocks_image_text_internal_link':
				return (<ContentBlocksImageTextInternalLink key={index} data={contentData.data} />)

			case 'list_type1':
				return (<ContentListType1 key={index} data={contentData.data} />)

			case 'location_map':
					return (<LocationMap key={index} data={contentData.data} />)
				
			default:
				break;
		}
	})
}

export default Content
