import React from "react"
import styles from './content_blocks_image_text.module.scss'
import Img from 'gatsby-image'

const ContentBlocksImageText = ({ data }) => {
	const { blocks } = data
	return(
		<ul
			className={styles.container}
		>
			{
				blocks.map((block ,key) => (
					<li key={key}>
						<Img
							fluid={block.image.childImageSharp.fluid}
							alt={block.description}
							loading="eager"
							backgroundColor="#666666"
							objectFit="contain"
						/>
						<h3><strong>{block.title}</strong> {block.subtitle}</h3>
						<div className={styles.text} dangerouslySetInnerHTML={{ __html: unescape(block.text) }} />
					</li>
				))
			}
		</ul>
	)
}

export default ContentBlocksImageText
