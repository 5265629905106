import React from "react"
import SEO from "../components/seo"
import posed from 'react-pose';
import { TransitionState } from "gatsby-plugin-transition-link"
import { graphql } from 'gatsby'
import Footer from '../components/footer'
import Breadcrumb from '../components/breadcrumb'

import Content from '../components/content_field/content_index'
import styles from './template-general.module.scss'

export const query = graphql`
	query($path: String!){
		pwPages(page_url: {eq: $path}) {
			title
			pwid
			page_url
			page_template
			lang
			seo{
				title
				description
				image {
					publicURL
				}
			}
			content {
				type
				data {
					title
					text
					image_url
					description
					image {
						childImageSharp {
							fluid(maxWidth: 2240, quality: 50) {
								...GatsbyImageSharpFluid_withWebp_noBase64
								aspectRatio
							}
						}
					}
					image_width
					blocks {
						description
						text
						title
						subtitle
						image_url
						image {
							childImageSharp {
								fluid(maxWidth: 2240, quality: 50) {
									...GatsbyImageSharpFluid_withWebp_noBase64
									aspectRatio
								}
							}
							publicURL
							extension
						}
						internal_url
					}
				}
			}
		}
	}
`;


const ContainerPosed = posed.div({
	visible: {
		opacity: 1,
		y: 0,
		transition: {
			ease: 'easeOut',
			duration: 900,
		},
		staggerChildren: 300
	},
	hidden: {
		opacity: 0,
		y: 100,
		transition: {
			ease: 'easeOut',
			duration: 900,
		},
	},
});

const Nivel1Page = all => {
	//onsole.log("all")
	//onsole.log(all)
	const { data, pageContext, path } = all
	const { lang } = pageContext
	const pageData = data.pwPages
	const content = pageData ? pageData.content : []
	const title = pageData ? pageData.title : ''
	const isLevel2 = pageContext.parentpage.pwid !== 1
	const parentPage = pageContext.parentpage

	return (
		<>
			<SEO
				title={pageData.seo.title}
				description={pageData.seo.description}
				image={pageData.seo.image.publicURL}
				lang={lang}
				pageurl={path}
				// schemaOrg={schemaOrg}
			/>
			<TransitionState>
				{({ transitionStatus, exit, enter }) => {
					const pose=transitionStatus === 'entering' || transitionStatus === 'entered' ? 'visible' : 'hidden'
					return (
						<ContainerPosed
							pose={pose}
							className={styles.container}
							>
							<Breadcrumb
								isVisible={isLevel2}
								parentPageTitle={parentPage.title}
								parentPageUrl={parentPage.page_url}
							/>
							<h1 className={`${styles.title} ${isLevel2 ? styles.titleForced : ''}`}>{title}</h1>
							<div
								className={styles.content}
							>
								<Content
									content={content}
								/>
							</div>
							<Footer
								lang={lang}
								isInternal={true}
							/>
						</ContainerPosed>
					)
				}}
			</TransitionState>
		</>
	)
}

export default Nivel1Page
