import React from "react"
import styles from './content_list_type1.module.scss'
import Img from 'gatsby-image'

const ContentTextHighlighted = ({ data }) => {
	const { blocks } = data
	return(
		
		<div
			className={styles.container}
		>
			<h2>{data.title}</h2>
			<ul>
				{
					blocks.map((block ,key) => (
						<li key={key}>
							{block.title}
						</li>
					))
				}
			</ul>
		</div>
	)
}

export default ContentTextHighlighted
