import React from "react"
import styles from "./breadcumb.module.scss"
import CustomLink from "./customLink"

const Breadcrumb = all => {
	const { isVisible, parentPageTitle, parentPageUrl} = all
	if(isVisible){
		return (
			<div
				className={styles.container}
			><CustomLink
				to={parentPageUrl}
			>{parentPageTitle}</CustomLink></div>
		)
	}
	return null;
}

export default Breadcrumb
