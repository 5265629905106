import React, { useState } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapGL, {Marker} from 'react-map-gl'

const LocationMap = ({ data }) => {
	const [viewport, setViewport] = useState({
    height: 400,
    latitude: 37.885141,
    longitude: -4.792283,
    zoom: 15
  });
	return (
		<>
			<ReactMapGL
				width='100%'
				{...viewport}
				mapboxApiAccessToken="pk.eyJ1IjoidG9uaWNhc3RpbGxvIiwiYSI6IkdqSDlrNmcifQ.NEv7kCYP8-mH86R19vLB6A"
				onViewportChange={setViewport}
			>
				<Marker latitude={37.885141} longitude={-4.792283} offsetLeft={10} offsetTop={-12}>
					<a href="https://goo.gl/maps/TH77mehpEYgsG2jP9" target="_blank">
          <div
						style={{
							position: 'relative',
							backgroundColor: 'rgba(255,255,255,0.8)',
							padding: '6px 10px',
							fontSize: '12px',
						}}
					>
						<div 
							style={{
								position: 'absolute',
								left: '-20px',
								backgroundColor: 'black',
								width: '14px',
								height: '14px',
								borderRadius: '50%',
							}}
						/>LAP Arquitectos</div>
					</a>
				</Marker>
			</ReactMapGL>
		</>
	)
}

export default LocationMap
