import React from "react"
import Img from 'gatsby-image'
import styles from './content_image_single.module.scss'

const ContentImageSingle = ({ data }) => {
	const { image, description, image_width } = data
	if(image && image.childImageSharp){
		return(
			<div className={styles.container}>
				<Img
					fluid={image.childImageSharp.fluid}
					alt={description}
					loading="eager"
					backgroundColor="#666666"
					objectFit="contain"
					className={styles.image}
					backgroundColor="white"
					style={{
						width: `${image_width}%`
					}}
				/>
			</div>
		)
	}
	// if(image){
	// 	return(
	// 		<div className={styles.container}>
	// 			<img src={image.publicURL} style={{
	// 				width: `${image_width}%`
	// 			}} />
	// 		</div>
	// 	)
	// }
	return(<div />)
	
}

export default ContentImageSingle
