import React from "react"
import styles from './content_blocks_image_text_internal_link.module.scss'
import Img from 'gatsby-image'
import CustomLink from '../customLink';

const ContentBlocksImageTextInternalLink = ({ data }) => {
	const { blocks } = data
	return(
		<ul
			className={styles.container}
		>
			{
				blocks.map((block ,key) => (
					<li key={key}>
						<CustomLink
										to={block.internal_url}
										isInternalLink={true}
									>
							<Img
								fluid={block.image.childImageSharp.fluid}
								alt={block.description}
								loading="eager"
								backgroundColor="#666666"
								objectFit="cover"
								style={{
									height: '8rem',
								}}
							/>
							<h3>{block.title}</h3>
							
							{
								block.text ? 
								<div
									dangerouslySetInnerHTML={{ __html: unescape(block.text) }}
									className={styles.text}
								/>
								:
								null
							}
						</CustomLink>
					</li>
				))
			}
		</ul>
	)
}

export default ContentBlocksImageTextInternalLink
